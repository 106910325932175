<template>
    <v-card tile class="pa-3 ma-10">
        <v-card-title>
            発注チェック<br>
        </v-card-title>
        <v-row class="pl-4">
            <v-col cols="3">
                <v-select v-model="selectedYW" :items="forecastYWsSelectOptions" style="width:300px;" />
            </v-col>
            <v-col cols="3">
                <v-select return-object v-model="selectedVersion" :items="forecastVersions" style="width:300px;" />
            </v-col>
        </v-row>
        <v-card-text>
            最新の需要予測結果から、要確認の商品一覧<span v-if="tableItems.length>0">(全 <b>{{ tableItems.length }}</b> 件)</span>を表示しています
        </v-card-text>

        <order-table
            :raw-items="tableItems"
            :loadingSave="loadingSave"
            :loadingDownload="loadingDownload"
            :loadedEdits="loadedTableEdits"
            :results="results"
            :duct="duct"
            @save="saveEdits"
            @download="downloadFile"
            />

        <snackbar ref="snackbar" />
    </v-card>
</template>

<script>
import { saveAs } from 'file-saver'
import OrderTable from './OrderTable'
import Snackbar from '@/components/ui/Snackbar'
    
export default {
    components: {
        OrderTable,
        Snackbar,
    },
    data: () => ({
        tableItems: [],
        results: {},
        loadedTableEdits: {},
        version: null,
        versionValue: '',

        forecastYWs: [],
        selectedYW: [],
        forecastVersions: [],
        selectedVersion: {},

        loadingSave: false,
        loadingDownload: false,
    }),
    props: ["duct"],
    computed: {
        forecastYWsSelectOptions() {
            return this.forecastYWs.map((yw) => ({ value: yw, text: `${yw[0]}年${yw[1]}週` })).reverse();
        }
    },
    methods: {
        saveEdits(edits) {
            this.loadingSave = true;
            this.duct.send(this.duct.nextRid(), this.duct.EVENT.TABLES_SET_EDITS, {
                edits,
                year: this.selectedYW[0],
                week: this.selectedYW[1],
                version: this.selectedVersion.value,
            });
        },
        downloadFile(edits) {
            this.loadingDownload = true;
            this.duct.send(this.duct.nextRid(), this.duct.EVENT.TABLES_DOWNLOAD, {
                edits,
                year: this.selectedYW[0],
                week: this.selectedYW[1],
                version: this.selectedVersion.value,
            });
        },
        redisStreamKeyToDateString(key) {
            const unixTime = parseInt(key.substr(0, key.length-3)*10);
            return new Date(unixTime).toLocaleString('ja-JP');
            //var date = new Date(unixTime * 1000);
            //var hours = date.getHours();
            //var minutes = "0" + date.getMinutes();
            //var seconds = "0" + date.getSeconds();
            //return hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
        }
    },
    watch: {
        async selectedYW(yw) {
            const versions = (await this.duct.call(this.duct.EVENT.TABLES_LIST_VERSIONS, { year: yw[0], week: yw[1] })).content
            this.forecastVersions = versions.map((version, i) => ({ value: version[0], text: `第${versions.length-i}版（${this.redisStreamKeyToDateString(version[0])}）`, idx: versions.length-i }));
            this.selectedVersion = this.forecastVersions[0];
        },
        async selectedVersion() {
            //console.log(this.selectedVersion);
            this.duct.send(this.duct.nextRid(), this.duct.EVENT.TABLES_GET, { year: this.selectedYW[0], week: this.selectedYW[1], version: this.selectedVersion.value });
            this.duct.send(
                this.duct.nextRid(),
                this.duct.EVENT.TABLES_GET_EDITS,
                {
                    year: this.selectedYW[0],
                    week: this.selectedYW[1],
                    version: this.selectedVersion.value
                }
            );
        }
    },
    created() {
        this.duct.invokeOnOpen(async () => {
            this.duct.addEventHandler(this.duct.EVENT.TABLES_GET_EDITS, {
                success: (rid,eid,data) => {
                    //console.log(data);
                    if(data && Object.keys(data).length>0) {
                        this.loadedTableEdits = data;
                    } else {
                        this.loadedTableEdits = {};
                    }
                },
                error: (rid,eid,data) => {
                    console.error(data);
                }
            });
            this.duct.addEventHandler(this.duct.EVENT.TABLES_GET, {
                success: async (rid,eid,data) => {
                    this.tableItems = data;
                    let groups = {};
                    for(let d of data){
                        if(!(d.forecast_type in groups)) groups[d.forecast_type] = [];
                        groups[d.forecast_type].push(d.hash_key);
                    }
                    let results = {};
                    //console.log(groups);
                    for(const ft in groups) {
                        for(const fp of this.duct.FORECAST_CONFIG.forecast_periods) {
                            const ret = await this.duct.call(this.duct.EVENT.RESULTS_GET_FOR_PRODUCT, {
                                    year: this.selectedYW[0],
                                    week: this.selectedYW[1],
                                    fp,
                                    ft,
                                    prd_hash_list: groups[ft]
                                });
                            results[`fp-${fp}`] = { ...results[`fp-${fp}`], ...ret.content };
                        }
                    }
                    this.results = results;
                },
                error: (rid,eid,data) => {
                    console.error(data);
                }
            });

            this.duct.setEventHandler(this.duct.EVENT.GET_STATUS, (rid,eid,status) => {
                if(status==this.duct.STATUS.FINISHED_POSTPROCESS) {
                    this.duct.send(
                        this.duct.nextRid(),
                        this.duct.EVENT.SET_STATUS,
                        { "StatusValue": this.duct.STATUS.IDLE }
                    );
                }
            });
            this.duct.addEventHandler(this.duct.EVENT.TABLES_SET_EDITS, {
                success: () => {
                    this.loadingSave = false;
                    this.$refs.snackbar.show("success", "保存が完了しました");
                },
                error: (rid,eid,data) => {
                    console.error(data);
                }
            });
            this.duct.addEventHandler(this.duct.EVENT.TABLES_DOWNLOAD, {
                success: (rid,eid,data) => {
                    this.loadingDownload = false;
                    saveAs(new Blob([data]), `${this.selectedYW[0]}-${('00'+this.selectedYW[1]).slice(-2)}-ver${this.selectedVersion.idx}.zip`);
                },
                error: (rid,eid,data) => {
                    console.error(data);
                }
            });

            const data = await this.duct.call(this.duct.EVENT.WEEK_FORECAST_GET_FOR_LATEST_TABLE);
            if(data.success){ this.selectedYW = data.content; }
            this.duct.send(this.duct.nextRid(), this.duct.EVENT.GET_STATUS);
            this.forecastYWs = (await this.duct.call(this.duct.EVENT.WEEK_FORECAST_GET_FOR_TABLES)).content;
        });
    }
}
</script>
