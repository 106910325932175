<template>
    <v-row class="mt-3 px-3">
        <v-menu
            offset-y
            v-model="shown"
            :close-on-content-click="false"
            transition="scale-transition"
            >
            <template v-slot:activator="{on,attrs}">
                <v-text-field
                    readonly
                    solo
                    dense
                    :value="selectedDateValue"
                    label="日付を範囲選択"
                    prepend-inner-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on = "on"
                    >
                </v-text-field>
            </template>
            <v-date-picker
                range
                v-model="selectedDates"
                locale="ja-jp"
                :day-format="date => new Date(date).getDate()"
                >
                <v-spacer />
                <v-btn
                    text
                    color="primary"
                    @click="shown = false"
                    >
                    キャンセル
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="shown = false"
                    >
                    確定
                </v-btn>
            </v-date-picker>
        </v-menu>
    </v-row>
</template>
<script>
export default {
    data: () => ({
        shown: false,
        selectedDates: [],
        rule: null,
    }),
    computed: {
        selectedDateValue() {
            return this.selectedDates.length>1
                ? `${this.selectedDates[0]}〜${this.selectedDates[1]}`
                : this.selectedDates[0];
        }
    },
    methods:{
        _updateRule() {
            const selectedDates = [...this.selectedDates];
            if(selectedDates.length){
                this.rule = selectedDates.length>1
                    ? (val) => (selectedDates[0]<=val && val<=selectedDates[1])
                    : (val) => (selectedDates[0]==val);
            } else {
                this.rule = null;
            }
        },
        resetRule() {
            this.selectedDates = [];
            this.rule = null;
        },
        getRule() {
            return this.rule;
        }
    },
    watch: {
        selectedDates() { this._updateRule(); },
    }
}
</script>
