<template>
    <v-menu
        bottom
        right
        offset-y
        :key="header.text"
        min-width="200"
        :rounded="true"
        :close-on-content-click="false"
        >
        <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
            <v-tooltip bottom :disabled="!header.description">
                <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip}">
                    <th 
                        valign="middle"
                        v-bind="{ ...attrsMenu, ...attrsTooltip }"
                        v-on="{ ...onMenu, ...onTooltip }"
                        class="text-no-wrap pa-4 tableHeader"
                        >
                        <span v-html="header.text"></span>
                        <v-badge
                            v-if="filterEnabled"
                            bordered
                            color="primary"
                            offset-y="8"
                            icon="mdi-filter"
                            />
                        <v-badge
                            v-if="sortPriority"
                            bordered
                            color="#FF5252"
                            offset-y="-10"
                            :content="sortPriority"
                            icon="mdi-icon"
                            />
                    </th>
                </template>   
                <span v-if="header.description" v-html="header.description" />
            </v-tooltip>
        </template>
        <v-card 
            dense 
            max-height="400" 
            class="overflow-y-auto"
            >
            <v-card-text>
                <div v-if="filterWindowComponent">
                    <component
                        :is="filterWindowComponent"
                        :ref="filterRefName"
                        :header="header"
                        :valueSet="valueSets[header.value] || null"
                        :valueTexts="valueTexts[header.value] || null"
                    />
                    <hr>
                </div>
                <sort-menu
                    :ref="sortRefName"
                    :header="header"
                />

                <v-row>
                    <v-col class="text-left">
                        <v-btn dark class="red darken-1" @click="resetRules">リセット</v-btn>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn color="primary" @click="confirmRules">完了</v-btn>
                    </v-col>
                </v-row>

            </v-card-text>
        </v-card>
    </v-menu>
</template>
<script>
import StringFilter from './filters/StringFilter'
import DateFilter from './filters/DateFilter'
import NumberFilter from './filters/NumberFilter'
import MultipleSelectFilter from './filters/MultipleSelectFilter'
import SortMenu from './filters/SortMenu'

export default {
    components: {
        SortMenu,
        StringFilter
    },
    data:()=>({
        filterRef:null,
        sortRef:null
    }),
    props: ['header', 'valueSets', 'valueTexts', 'sortPriority', 'filterEnabled'],
    computed: {
        filterRefName() { return `filter_${this.header.value}`; },
        sortRefName() { return `sort_${this.header.value}`; },
        filterWindowComponent() {
            if(this.header.filterType=='string')         return StringFilter;
            else if(this.header.filterType=='number')    return NumberFilter;
            else if(this.header.filterType=='date')      return DateFilter;
            else if(this.header.filterType=='multiple')  return MultipleSelectFilter;
            else return null;
        }
    },
    methods: {
        resetRules(){
            if([this.filterRef, this.sortRef].includes(null)) this.getRefs();
            this.sortRef.resetRule();
            this.filterRef ? this.filterRef.resetRule() : null;
            this.confirmRules();
        },
        confirmRules(){
            if([this.filterRef, this.sortRef].includes(null)) this.getRefs();
            this.emitUpdateRules();
        },
        emitUpdateRules(){
            this.$emit(
                'update-rules',
                this.header,
                this.filterRef ? this.filterRef.getRule() : null,
                this.sortRef.getRule()
            );
        },
        getRefs(){
            const _filterRefName = this.filterRefName;
            const _sortRefName = this.sortRefName;
            this.filterRef = this.$refs[_filterRefName];
            this.sortRef = this.$refs[_sortRefName];
        }
    },
}
</script>
<style>
th.tableHeader{
    font-family:   "Roboto", sans-serif;
    font-weight:   bold;
    border-bottom: 2px solid #555555;
    font-size:     12px;
    opacity:       1;
    background-color: #CFD8DC;/*Equivalent to blue-grey lighten-4*/
    position: sticky;
    top:           0;
    z-index:  1;
}
th.tableHeader:hover {
    background-color: #B0BEC5;/*Equivalent to blue-grey lighten-3*/
}
</style>
