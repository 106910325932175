<template>
    <div>
        <v-row class="mt-3 px-3">
            <v-select
                solo
                dense
                prepend-inner-icon="mdi-filter-outline"
                v-model="selectedOperand"
                :items="operands"
                item-value="val"
                item-text="title"
                />
        </v-row>
        <div v-if="selectedOperand && !isRangeOperand">
            <v-text-field dense solo v-model="comparedValue" label="値を入力" type="number" />
        </div>
        <div v-else-if="selectedOperand && isRangeOperand">
            <v-text-field dense solo v-model="rangeValues[0]" label="下限を入力" type="number" />
            から
            <v-text-field dense solo v-model="rangeValues[1]" label="上限を入力" type="number" />
        </div>
    </div>
</template>
<script>
export default{
    data: () =>(
        {
            operands: [
                { title: '次より大きい',  val: '>' },
                { title: '以上',          val: '>=' },
                { title: '次より小さい',  val: '<' },
                { title: '以下',          val: '<=' },
                { title: '次と等しい',    val: '==' },
                { title: '次と等しくない',val: '!=' },
                { title: '次の間にある',  val: '~' },
                { title: '次の間にない',  val: '!~' },
            ],
            selectedOperand: '',
            comparedValue: null,
            rangeValues: [null, null],
        }
    ),
    computed: {
        isRangeOperand() { return ['~', '!~'].includes(this.selectedOperand); }
    },
    methods:{
        _updateRule() {
            if(this.selectedOperand && (this.comparedValue || (this.rangeValues[0] && this.rangeValues[1]))) {
                const op = JSON.parse(JSON.stringify(this.selectedOperand));
                const cmp = JSON.parse(JSON.stringify(this.comparedValue));
                const range = JSON.parse(JSON.stringify(this.rangeValues));
                if(['>' ,'>=' ,'<' ,'<=' ,'==' ,'!='].includes(op))
                    this.rule = (val) => (eval(`${val}${op}${cmp}`));
                else if(op=='~')
                    this.rule = (val) => ((range[0] <= val) && (val >= range[1]));
                else if(op=='!~')
                    this.rule = (val) => ((val < range[0]) || (range[1] < val));
                else
                    this.rule = null;
            } else {
                this.rule = null;
            }
            console.log(this.rule);
        },
        resetRule() {
            this.rule = null;
            this.selectedOperand = '';
            this.comparedValue = null;
            this.rangeValues = [null, null];
        },
        getRule() {
            return this.rule;
        }
    },
    watch: {
        selectedOperand() { this._updateRule(); },
        comparedValue() { this._updateRule(); },
        rangeValues() { this._updateRule(); },
    }
}
</script>

