<template>
    <v-row class="mt-3 mb-4 px-3">
        <v-text-field
            hide-details
            dense
            solo
            v-model="inputValue"
            prepend-inner-icon='mdi-magnify'
        />
    </v-row>
</template>
<script>
export default {
    data: ()=>({
        inputValue: '',
    }),
    methods:{
        _updateRule() {
            const inputValue = JSON.parse(JSON.stringify(this.inputValue));
            this.rule = inputValue ? (val) => (val.indexOf(inputValue)>-1) : null;
        },
        resetRule() {
            this.inputValue = '';
            this.rule = null;
        },
        getRule() {
            return this.rule;
        }
    },
    watch: {
        inputValue() { this._updateRule(); },
    }
}
</script>
