<template>
    <div>
        <v-simple-table dense>
            <template v-slot:default>
                <tbody>
                    <tr
                        v-for="col in itemAttributes.filter((c) => (c.level<=1))"
                        :key="col.value"
                        >
                        <th style="width:25%">{{ col.text }}</th>
                        <td :colspan="serviceRates.length">
                            <span v-if="col.value=='months_order'">{{ selectedTableItem[col.value].toFixed(1) }}</span>
                            <span v-else-if="col.value=='months_stock'">{{ selectedTableItem[col.value].toFixed(1) }}</span>
                            <span v-else-if="col.value=='price'">{{ selectedTableItem[col.value].toFixed(3) }}</span>
                            <span v-else-if="col.value=='order_price'">\{{ selectedTableItem[col.value].toLocaleString() }}</span>
                            <span v-else>{{ selectedTableItem[col.value] }}</span>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-simple-table dense>
            <template v-slot:default>
                <tbody>
                    <tr>
                        <th style="width:25%">需要統計情報</th>
                        <th v-for="w in [8,16,32,64]" :key="`header-stat-${w}`">過去{{ w }}週</th>
                    </tr>
                    <tr
                        v-for="s in ['df']"
                        :key="`${s}`">
                        <th v-if="s=='df'" style="text-align:right;">頻度 (P)</th>
                        <td v-for="w in [8,16,32,64]" :key="`s${s}w${w}`">
                            {{ (selectedTableItem[`${s}_last${w}`]).toFixed(1) }}
                        </td>
                    </tr>
                    <tr
                        v-for="s in ['ave', 'std']"
                        :key="`${s}`">
                        <th v-if="s=='ave'" style="text-align:right;">平均 ({{ selectedTableItem.order_unit }})</th>
                        <th v-if="s=='std'" style="text-align:right;">標準偏差 ({{ selectedTableItem.order_unit }})</th>
                        <td v-for="w in [8,16,32,64]" :key="`s${s}w${w}`">
                            {{ (selectedTableItem[`${s}_last${w}`] / selectedTableItem.unit_qty).toFixed(2) }}
                        </td>
                    </tr>
                    <tr></tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-simple-table dense>
            <template v-slot:default>
                <tbody>
                    <tr>
                        <th style="width:25%">現在庫</th>
                        <td >{{((selectedTableItem.stocks_all) / selectedTableItem.unit_qty).toFixed(2)}} {{ selectedTableItem.order_unit }}  （バラ数：{{selectedTableItem.stocks + selectedTableItem.remains}}）</td>
                    </tr>
                    <tr>
                        <th style="width:25%">発注点</th>
                        <td > {{ selectedTableItem[`order_point_fp${selectedTableItem.fp}_sr${selectedTableItem.sr}`] }} {{ selectedTableItem.order_unit }}（ {{ selectedTableItem.fp }}週後予測 / 累積事後確率{{ selectedTableItem.sr }} % ）</td>
                    </tr>
                    <tr></tr>
                </tbody>
            </template>
        </v-simple-table>
        <!-- <v-simple-table dense>
            <template v-slot:default>
                <tbody>
                    <tr>
                        <th style="width:25%">発注点</th>
                        <th v-for="sr in serviceRates" :key="`header-${sr}`">{{ sr }}%</th>
                    </tr>

                    <tr
                        v-for="fp in forecastPeriods"
                        :key="`fp${fp}`">
                        <th style="text-align:right;">{{ fp }} 週後予測</th>
                        <td v-for="sr in serviceRates" :key="`fp${fp}sr${sr}`">
                            <span
                                v-if="selectedTableItem.fp==fp || selectedTableItem.sr==sr"
                                :style="
                                    'color:#1976d2;' +
                                    ((selectedTableItem.fp==fp && selectedTableItem.sr==sr)
                                        ? 'font-weight:bold;'
                                        : '')
                                "
                                >
                                {{ selectedTableItem[`order_point_fp${fp}_sr${sr}`] }}
                            </span>
                            <span v-else>
                                {{ selectedTableItem[`order_point_fp${fp}_sr${sr}`] }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table> -->
        <v-simple-table dense>
            <template v-slot:default>
                <tbody>
                    <tr>
                        <th style="width:25%">予測需要</th>
                        <th v-for="fp in forecastPeriods" :key="`header-${fp}`">{{ fp }}週後予測</th>
                    </tr>

                    <tr
                        v-for="sr in serviceRates"
                        :key="`sr${sr}`">
                        <th style="text-align:right;">累積事後確率 {{ sr }} %</th>
                        <td v-for="fp in forecastPeriods" :key="`fp${fp}sr${sr}`">
                            <span
                                v-if="selectedTableItem.fp_shp==fp || selectedTableItem.sr==sr"
                                :style="
                                    'color:#1976d2;text-align:right' +
                                    ((selectedTableItem.fp_shp==fp && selectedTableItem.sr==sr)
                                        ? 'font-weight:bold;'
                                        : '')
                                "
                                >
                                {{ selectedTableItem[`order_point_fp${fp}_sr${sr}`] }}
                            </span>
                            <span v-else style="text-align:right">
                                {{ selectedTableItem[`order_point_fp${fp}_sr${sr}`] }}
                            </span>
                        </td>
                    </tr>
                    <tr
                        v-if="'ml-gbdt-cls_fp1_pred' in selectedTableItem">
                        <th style="text-align:right;">事後確率最大（事後確率）</th>
                        <td v-for="fp in forecastPeriods" :key="`gbdt-cls-fp${fp}`">
                            <span
                                v-if="selectedTableItem.fp_shp==fp"
                                :style="
                                    'color:#1976d2;text-align:right' +
                                    ((selectedTableItem.fp_shp==fp)
                                        ? 'font-weight:bold;'
                                        : '')
                                "
                                >
                                {{ selectedTableItem[`ml-gbdt-cls_fp${fp}_pred`] }}
                            </span>
                            <span v-else style="text-align:right">
                                {{ selectedTableItem[`ml-gbdt-cls_fp${fp}_pred`] }}
                            </span>
                            <span style="text-align:right">
                                ( {{ selectedTableItem[`ml-gbdt-cls_fp${fp}_posterior`].toFixed(2) }} )
                            </span>
                        </td>
                    </tr>
                    <tr
                        v-if="'ml-gbdt-reg_fp1_pred' in selectedTableItem">
                        <th style="text-align:right;">GBDT回帰予測</th>
                        <td v-for="fp in forecastPeriods" :key="`gbdt-reg-fp${fp}`">
                            <span
                                v-if="selectedTableItem.fp_shp==fp"
                                :style="
                                    'color:#1976d2;text-align:right' +
                                    ((selectedTableItem.fp_shp==fp)
                                        ? 'font-weight:bold;'
                                        : '')
                                "
                                >
                                {{ selectedTableItem[`ml-gbdt-reg_fp${fp}_pred`] }}
                            </span>
                            <span v-else style="text-align:right">
                                {{ selectedTableItem[`ml-gbdt-reg_fp${fp}_pred`] }}
                            </span>
                        </td>
                    </tr>
                    <tr></tr>            
                </tbody>
                <tbody v-if="'classical_fp1_sr60' in selectedTableItem">
                    <tr v-for="sr in serviceRates"
                        :key="`classical-sr${sr}`">
                        <th style="text-align:right;">古典的・安全率 {{ sr }} %</th>
                        <td v-for="fp in forecastPeriods" :key="`classical-fp${fp}sr${sr}`">
                            <span
                                v-if="selectedTableItem.fp_shp==fp"
                                :style="
                                    'color:#1976d2;text-align:right' +
                                    ((selectedTableItem.fp_shp==fp)
                                        ? 'font-weight:bold;'
                                        : '')
                                "
                                >
                                {{ selectedTableItem[`classical_fp${fp}_sr${sr}`] }}
                            </span>
                            <span v-else style="text-align:right">
                                {{ selectedTableItem[`classical_fp${fp}_sr${sr}`] }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
import { itemAttributes, forecastPeriods, serviceRates } from './table_profile.js'

export default {
    data: () => ({
        itemAttributes,
        forecastPeriods,
        serviceRates,
    }),
    props: ["selectedTableItem"],
}
</script>
