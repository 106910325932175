<template>
    <v-data-table
        v-bind="dataTableProps"
        :items="displayedItems"
        :headers="headers"
    >
        <template v-slot:header>
            <filterable-table-header
                ref="header"
                v-for="header in headers"
                :key="header.value"
                :header="header"
                :valueSets="valueSets"
                :valueTexts="valueTexts"
                :sortPriority="sortPriority[header.value]"
                :filterEnabled="header.value in filterRules"
                @update-rules="applyRules"
            />
        </template>
        <template 
            v-for="slot in dataTableSlots" 
            v-slot:[`${slot}`]= "attrs"
         >  
            <slot v-bind="attrs" :name="slot" />
        </template>
    </v-data-table>
</template>
<script>
import FilterableTableHeader from './FilterableTableHeader.vue'
export default{
    components:{
        FilterableTableHeader
    },
    data:() => ({
        filterRules: {},
        sortRules:[]
    }),
    props:{
        rawItems: {
            type: Array,
            default: () => ([])
        },
        headers: {
            type: Array,
            default: () => ([])
        },
        dataTableProps: {
            type: Object,
            default: () => ({})
        },
        headerProps: {
            type: Object,
            default: () => ({
                alertTypes: {},
                filterTypes: {}
            })
        },
        dataTableSlots:{
            type: Array,
            default: () => ({})
        }
    },
    computed: {
        valueSets() {  // only needed for cols of filterType=='multiple'
            let sets =
                Object.fromEntries(
                    Object.entries(this.headerProps.filterTypes)
                    .flatMap(([headerValue, filterType]) => filterType=='multiple' ? [[headerValue, new Set()]] : [])
                );
            this.rawItems.forEach((item) => {
                Object.entries(sets).forEach(([header, set]) => { set.add(item[header]) });
            });
            return sets;
        },
        valueTexts(){
            let _obj = { alerts:{} };
            _obj.alerts = Object.fromEntries(Object.entries(this.headerProps.alertTypes).map((a) => [a[0],a[1].text]));
            return _obj
        },
        displayedItems(){
            let _items = [...this.rawItems];
            _items = this.sortItems(_items);
            _items = this.filterItems(_items);
            return _items;
        },
        sortPriority() {
            let p = {};
            this.sortRules.forEach((rule,i) => { p[rule.headerValue] = i+1 });
            return p;
        },
    },
    methods:{
        applyRules(header, filterRule, sortRule) {
            // filter
            if(filterRule!=null) this.$set(this.filterRules, header.value, filterRule);
            else this.$delete(this.filterRules, header.value);

            // sort
            const idx = this.sortRules.findIndex((rule) => rule.headerValue==header.value);
            if(idx==-1 && sortRule) {
                this.sortRules.push({ headerValue: header.value, func: sortRule })
            } else if (idx>-1) {
                if(sortRule) this.sortRules.splice(idx, 1, { headerValue: header.value, func: sortRule });
                else this.sortRules.splice(idx, 1);
            }
        },
        sortItems(items) {
            [...this.sortRules].reverse().forEach(
                (rule) => { items.sort(rule.func); }
            );
            return items;
        },
        filterItems(items) {
            return items.filter((item) => {
                for(const [headerValue, func] of Object.entries(this.filterRules)){
                    if(!func(item[headerValue])) return false;
                }
                return true;
            });
        },
    },
}
</script>
