<template>
    <v-radio-group v-model="sortDirection">
        <v-radio label="ソートしない" value="noSort" color="primary" />
        <v-radio label="昇順にソート" value="ascend" color="primary" />
        <v-radio label="降順にソート" value="descend" color="primary" />
    </v-radio-group>
</template>
<script>
export default {
    data: () => ({
        sortDirection: 'noSort',
        rule: null,
    }),
    props: ['header'],
    methods: {
        resetRule() {
            this.sortDirection = 'noSort';
            this.rule = null;
        },
        getRule() {
            return this.rule;
        }
    },
    watch: {
        sortDirection(val) {
            console.log(val);
            if(val=='ascend') {
                this.rule = (a,b) => (a[this.header.value]>b[this.header.value] ? 1 : -1);
            } else if(val=='descend') {
                this.rule = (a,b) => (a[this.header.value]>b[this.header.value] ? -1 : 1);
            } else {
                this.rule = null;
            }
        }
    }
}
</script>
