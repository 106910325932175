<template>
    <div>
        <v-row class="mt-3 px-3">
            <v-select
                chips
                multiple
                dense
                clearable
                solo
                v-model="checkedItems"
                ref="select"
                label="絞り込みなし" 
                :items="selectItems"
                item-text="text"
                item-value="value"
                >
                <template v-slot:prepend-item>
                    <v-list-item
                        v-if="$refs.select"
                        @click="$refs.select.blur"
                        >
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-list-item>
                </template>
            </v-select>
        </v-row>

        <v-row v-if="itemMayBeArray" class="mt-0 px-3">
            <v-radio-group row v-model="logicalOperator">
                <v-radio label="いずれかを含む" value="or" color="orange" />
                <v-radio label="全て含む" value="and" color="orange" />
                <v-radio label="含まない" value="nor" color="orange" />
            </v-radio-group> 
        </v-row>

    </div>
</template>
<script>
export default{
    data: () =>({
        rule: null,
        checkedItems: [],
        logicalOperator: 'or',
    }),
    props: ['header', 'valueSet', 'valueTexts'],

    computed: {
        selectItems() {
            let items = Array.from(new Set(Array.from(this.valueSet).flat()));
            return this.valueTexts ? items.map((item) => ({ text: this.valueTexts[item], value: item })) : items;
        },
        itemMayBeArray() {
            return Array.from(this.valueSet).some((v) => Array.isArray(v));
        },
    },
    methods: {
        updateRule() {
            const checkedItems = [...this.checkedItems];
            const op = JSON.parse(JSON.stringify(this.logicalOperator));
            if(checkedItems.length) {
                if(this.itemMayBeArray) {
                    if(op == 'or') {
                        this.rule = (val) => (
                            val.filter((v) => checkedItems.includes(v)).length>0
                            ? true : false
                        );
                    } else if(op == 'and') {
                        this.rule = (val) => (
                            val.filter((v) => checkedItems.includes(v)).length == checkedItems.length
                            ? true : false
                        );
                    } else if(op == 'nor') {
                        this.rule = (val) => (
                            val.filter((v) => checkedItems.includes(v)).length==0
                            ? true : false
                        );
                    } else {
                        this.rule = null;
                    }
                } else {
                    this.rule = (val) => (checkedItems.length==0 || checkedItems.includes(val));
                }
            } else {
                this.rule = null;
            }
        },
        resetRule() {
            this.rule = null;
            this.checkedItems = [];
            this.logicalOperator = 'or';
        },
        getRule() {
            return this.rule;
        }
    },
    watch: {
        checkedItems() { this.updateRule(); },
        logicalOperator() { this.updateRule(); },
    }
}
</script>
