<template>
    <v-row class="ma-0">
        <v-col v-if="numFilteredItems == numItems" cols="auto"  md="auto" class="font-weight-regular text--secondary ml-1 itemAmount mt-4">
            フィルタを設定していません
        </v-col>
        <v-col  v-else cols="auto"  md="auto" class="font-weight-regular text--secondary ml-1 itemAmount mt-4">
            フィルタ設定中：<b>{{numFilteredItems}}</b>件を表示しています
        </v-col>
        <v-col class="mx-auto text-right">
            <v-btn class="ml-2" :loading="loadingSave" @click="$root.$emit('save');">
                <v-icon>mdi-content-save</v-icon>
                一時保存
            </v-btn>
            <v-btn color="primary" :loading="loadingDownload" @click="$root.$emit('download');" class="ml-2">
                <v-icon>mdi-download</v-icon>
                発注データ出力
            </v-btn>
        </v-col>
    </v-row>
</template>
<script>
export default {
    data: () => ({
        //numFilteredItems: 0
    }),
    props: [
        'loadingDownload',
        'loadingSave',
        'numFilteredItems',
        'numItems',
    ],
    created() {
        //this.$root.$on('item-ready', (num) => {
        //    this.numFilteredItems = num;
        //});
    }
}
</script>
<style>
.itemAmount {
    font-size: 0.875rem;
    bottom: 0;
}
</style>
