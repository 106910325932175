<template>
    <div>
        <iflb-table
            class="text-no-wrap"
            :headers="headers"
            :raw-items="rawItems"
            :data-table-props="{
                'fixed-header':true,
                'multi-sort':true,
                'dense':true,
                'hide-default-header':true,
                'height':700,
                'item-key':'product_code',
                'footer-props': { itemsPerPageOptions: [10,30,50,100,300,-1]},
                'items-per-page':50,
                'loading':loading,
                'item-class': (item)=> (hasEdit(item) ? 'edited' : ''),
            }"
            :data-table-slots="dataTableSlots"
            :header-props="{
                alertTypes:alertType,
                filterTypes:headerFilterTypes
            }"
        >
            <template v-slot:item.checked="{ item }">
                <v-checkbox
                    hide-details
                    class="py-0 my-0"
                    color="primary"
                    v-model="item.checked"
                    @click="updateEditedTableItem({
                            item,
                            optionValue: { sr: item.sr, fp: item.fp_shp },
                            forceZero: item.do_not_order,
                        }, !item.checked)"
                    />
            </template>
            <template v-slot:item.editAction="{ item }">
                <v-icon
                    @click.stop="openEditDialog(item)"
                    :color="hasEdit(item) ? 'warning' : ''">
                    mdi-pencil
                </v-icon>
            </template>
    
            <template v-slot:item.alerts="{ item }">
                <v-chip
                    v-for="alert in item.alerts"
                    dark
                    small
                    :key="`${item.product_code}-alert-${alertType[alert].text}`"
                    :color="alertType[alert].color"
                    >
                    {{ alertType[alert].text }}
                </v-chip>
            </template>

            <template v-slot:item.order_num="{ item }">
                <div v-if="hasEdit(item)" v-html="editedItemHTML(item, 'order_num')"></div>
                <div v-else>
                    <span style="font-weight:bold;color:rgb(26 118 210);">{{ item.order_num }}</span>
                </div>
            </template>

            <template v-slot:item.order_unit="{ item }">
                <span v-if="item.order_unit_orig">
                    <s>{{ item.order_unit_orig }}</s><br>
                    <b>{{ item.order_unit }}</b>
                </span>
                <span v-else>
                    {{ item.order_unit }}
                </span>
            </template>
            <template v-slot:item.price="{ item }">
                \{{ item.price.toFixed(3) }}
            </template>
            <template v-slot:item.months_order="{ item }">
                {{ item.months_order ? item.months_order.toFixed(1) : 0 }}
            </template>
            <template v-slot:item.months_stock="{ item }">
                {{ item.months_stock ? item.months_stock.toFixed(1) : 0 }}
            </template>

            <template v-slot:item.sr="{ item }">
                <div v-html="editedItemHTML(item, 'sr')"></div>
            </template>
            <template v-slot:item.fp="{ item }">
                <span v-if="item.forecast_period_orig" style="color:#bbb"><s>{{ item.forecast_period_orig }}</s>&nbsp;&nbsp;</span>
                <span v-else></span>
                <div v-html="editedItemHTML(item, 'fp')"></div>
            </template>
            <template v-slot:item.fp_shp="{ item }">
                <span v-if="item.forecast_period_ship_orig" style="color:#bbb"><s>{{ item.forecast_period_ship_orig }}</s>&nbsp;&nbsp;</span>
                <span v-else></span>
                {{ item.fp_shp }}
            </template>
            <template v-slot:item.order_point="{ item }">
                <div v-html="editedItemHTML(item, 'order_point')"></div>
            </template>
            <template v-slot:item.order_num_qty="{ item }">
                <div v-html="editedItemHTML(item, 'order_num_qty')"></div>
            </template>
            <template v-slot:item.order_price="{ item }">
                <div v-html="editedItemHTML(item, 'order_price', (x) => ('\\'+Math.round(x).toLocaleString()) )"></div>
            </template>
            <template v-slot:item.order_num_calc="{ item }">
                <div v-html="editedItemHTML(item, 'order_num_calc')"></div>
            </template>

            <template v-for="i in [0,1,2,3,4]" v-slot:[`item.custom${i}`]="{ item }">
                <span v-if="item[`custom${i}`]!==-1" :key="`item.custom${i}`">{{ item[`custom${i}`] }}</span>
            </template>

            <template v-slot:top="{ originalItemsLength }">
                <table-tool-bar
                    :loadingSave="loadingSave"
                    :loadingDownload="loadingDownload"
                    :numFilteredItems="originalItemsLength"
                    :numItems="rawItems.length"
                />
            </template>
        </iflb-table>
        <row-edit-dialog
            ref="dialogEdit"
            :selectedTableItem="selectedTableItem"
            :currentOptionValue="currentOptionValue"
            :isForceZero="isForceZero"
            :results="results"
            :duct="duct"
            :manualValue="manualValue"
            @confirm="updateEditedTableItem"
            />
    </div>
</template>

<script>
import RowEditDialog from './RowEditDialog'
import TableToolBar from './TableToolBar'
import IflbTable from './custom_table/IflbTable'
import {
    alertType,
    headerFilterTypes,
    itemAttributes,
    calcOrderNum,
} from './table_profile.js'

export default {
    components: {
        RowEditDialog,
        TableToolBar,
        IflbTable
    },
    data: () => {
        return {
            dataTableSlots:[
                'item.checked',
                'item.editAction',
                'item.alerts',
                'item.order_num',
                'item.order_unit',
                'item.price',
                'item.months_order',
                'item.months_stock',
                'item.sr',
                'item.fp',
                'item.fp_shp',
                'item.order_point',
                'item.order_num_qty',
                'item.order_price',
                'item.order_num_calc',
                'item.custom0',
                'item.custom1',
                'item.custom2',
                'item.custom3',
                'item.custom4',
                'top',
            ],
            headerFilterTypes,
            alertType,
            loading: true,

            edits: {},
            currentOptionValue: {},
            isForceZero: false,
            manualValue: null,

            selectedTableItem: {},

            filterRules: {},
            sortRules: [],

            headers: [
                { value: 'checked', text: '確認' },
                { value: 'editAction', text: '編集' },
                ...itemAttributes.filter((c) => (c.level==0)),
            ],
        }
    },
    props: ['rawItems', 'results', 'loadingDownload','loadingSave','loadedEdits', 'loadedChecks', 'duct'],
    methods: {
        openEditDialog(item){
            if(!this.hasEdit(item)) {
                //console.log(`[${item.product_code}] has no edit.`);
                //this.currentOptionValue = { sr: item.sr, fp: item.fp };
                this.currentOptionValue.sr = 0;
                this.currentOptionValue.fp = 0;
                this.currentOptionValue.src = "";
                this.selectedTableItem = item;
                this.isForceZero = false;
            } else {
                const original = this.edits[item.product_code].original;
                const edit = this.edits[item.product_code].edit;
                //console.log(`[${item.product_code}] has edit. sr=${edit.sr}, fp_shp=${edit.fp_shp}, src=${edit['src']}`);

                this.currentOptionValue.sr = edit.sr;
                this.currentOptionValue.fp = edit.fp_shp;
                if('src' in edit) {
                    this.currentOptionValue.src = edit.src != null ? edit.src : "";
                }
                if(item.manualValue) {
                    this.currentOptionValue.manual = true;
                    this.manualValue = item.manualValue;
                }
                this.selectedTableItem = original;
                this.isForceZero = edit.do_not_order;
            }

            this.$refs.dialogEdit.show();
        },
        hasEdit(item){
            return (item.product_code in this.edits) && (item.editAction==1);
        },
        editedItemHTML(item, col, formatter = x=>x){
            if(this.hasEdit(item)) {
                const original = this.edits[item.product_code].original[col];
                const edit = this.edits[item.product_code].edit[col];

                if(edit!=original) {
                    return `<span style="text-decoration:line-through;">${formatter(original)}</span><br><span style="font-weight:bold;color:#ff9800;">${formatter(edit)}</span>`;
                }
            }

            return `<span>${formatter(item[col])}</span>`;
        },
        updateItemInTable(item){
            const rowNum = this.rawItems.findIndex((r) => (r.product_code==item.product_code));
            //console.log(`updateItemInTable ${rowNum}`)
            this.rawItems.splice(rowNum, 1, item);
        },
        updateItemsInTable() {
            //console.log(`updateItemsInTable loading=${this.loading}, rawItems=${this.rawItems.length}, edits=${this.edits.length}....`);
            if(this.loading && this.loadedEdits && this.rawItems.length > 1) {

                this.edits = JSON.parse(JSON.stringify(this.loadedEdits));
                for(const productCode in this.edits){
                    let rowNum = this.rawItems.findIndex((r) => (r["product_code"]==productCode));
                    let item = this.edits[productCode].edit;
                    this.rawItems.splice(rowNum, 1, item);
                }
                this.loading = false;
            }
        },
        updateEditedTableItem({ item, optionValue, manualValue, forceZero }, unchecked = false){
            const productCode = item.product_code;
            if(!(productCode in this.edits)) {
                //console.log(`[${item.product_code}] new editItem created. option=${JSON.stringify(optionValue)}`);
                this.edits[productCode] = {
                    original: JSON.parse(JSON.stringify(item)),
                };
            }
            item = JSON.parse(JSON.stringify(this.edits[productCode].original));
            if(forceZero){
                item.do_not_order = true;
                item.order_num = 0;
                item.order_price = 0;
            } else if(manualValue) {
                item.manualValue = manualValue;
                item.order_num = manualValue;
                item.order_num_qty = item.order_num * item.unit_qty;
                item.order_price = item.order_num_qty * item.price;
            } else if(optionValue.sr == 0 && optionValue.fp == 0) {
                // ignore edits. use default option.
            } else {
            //} else if(item.sr!=optionValue.sr || item.fp_shp!=optionValue.fp) {
                const src_key_map = {
                    classical: (sr, fp) => `classical_fp${fp}_sr${sr}`,
                    'gbdt-cls': (sr, fp) => `ml-gbdt-cls_fp${fp}_pred`,
                    'gbdt-reg': (sr, fp) => `ml-gbdt-reg_fp${fp}_pred`,
                    default: (sr, fp) => `order_point_fp${fp}_sr${sr}`,
                };
                item.sr = optionValue.sr;
                item.fp_shp = optionValue.fp;
                item.src = optionValue.src;
                item.do_not_order = false;
                item.order_point = item[src_key_map[item.src in src_key_map ? item.src : 'default'](item.sr, item.fp_shp)];
                item.order_num_calc = calcOrderNum(item);
                item.order_num = item.order_num_calc;
                item.order_num_qty = item.order_num * item.unit_qty;
                item.order_price = item.order_num_qty * item.price;
            }
            for(const key of ['sr', 'do_not_order', 'order_point', 'order_num_calc', 'order_price', 'order_num']){
                if(this.edits[productCode].original[key]!=item[key]) {
                    item.editAction = 1;
                    break;
                }
                item.editAction = 0;
            }
            item.checked = !unchecked;

            //console.log(`[${item.product_code}] editItem updated. edit=${item.editAction}, sr=${item.sr}, fp_shp=${item.fp_shp}, src=${item.src}, misc=${item.do_no_order ? "do_not_order," : ""} ${item.manualValue ? manualValue : ""}${forceZero ? "forceZero" : ""}`);

            this.edits[productCode].edit = item;
            this.updateItemInTable(item);
        }   ,
        async removeNoDiffEdits(){
            for(const prdCode in this.edits){
                const itemEdit = this.edits[prdCode];
                let edited = false;
                for(const key in itemEdit.original){
                    if(itemEdit.original[key]!=itemEdit.edit[key]) {
                        edited = true;
                        break;
                    }
                }
                if(edited==false) delete this.edits[prdCode];
            }
            return 1;
        },

    },
    watch: {
        rawItems() {
            this.updateItemsInTable();
        },
        loadedEdits() {
            this.updateItemsInTable();
            if(this.rawItems && this.loadedEdits) {
                this.edits = JSON.parse(JSON.stringify(this.loadedEdits));
                for(const productCode in this.edits){
                    let rowNum = this.rawItems.findIndex((r) => (r["product_code"]==productCode));
                    let item = this.edits[productCode].edit;
                    this.rawItems.splice(rowNum, 1, item);
                }
            }
        },
        displayedItems(val){
            this.$root.$emit('item-ready', val.length);
        },
    },
    created() {
        if(this.rawItems.length) this.loading = false;
        this.$root.$on('save', async () => {
            await this.removeNoDiffEdits();
            this.$emit('save', this.edits);
        });
        this.$root.$on('download', async () => {
            await this.removeNoDiffEdits();
            this.$emit('download', this.edits);
        });
    },
}
</script>
<style>
.edited {
    background-color: #fff2e1;
}
.edited:hover {
    background-color: #ffedd6 !important;
}
</style>
