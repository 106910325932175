<template>
    <v-dialog
        v-model="dialog"
        max-width="800"
        >
        <v-card class="overflow-hidden">
            <v-card-title>発注情報を編集する</v-card-title>

            <v-sheet id="details" class="overflow-y-auto" max-height="600" style="padding-bottom:64px;">
                <v-container class="pa-0">
                    <v-divider />
                    <row-details-table :selectedTableItem="selectedTableItem" />
                    <v-tabs height="30" class="ma-3" v-model="graphTab">
                        <v-tab v-for="fp in fps" :key="`graph${fp}`">{{ fp }}週</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="graphTab">
                        <v-tab-item v-for="fp,i in fps" :key="`graph${fp}`">
                            <results-bar-chart :chartData="chartData[i]" :options="chartOptions[i]" :height="150" />
                        </v-tab-item>
                    </v-tabs-items>
                    <v-divider />
                </v-container>
            </v-sheet>

            <v-app-bar
                flat
                absolute
                bottom
                color="white"
                scroll-target="#details"
                align="start"
                >

                <v-row class="px-2">
                    <v-col>
                    <v-select 
                        hide-details
                        v-model="selectedOptionValue"
                        :items="srOptions"
                        :disabled="forceZero"
                        />
                    </v-col>
                </v-row>

                <v-row class="px-2">
                    <v-col>
                    <v-text-field 
                        v-if="isManual"
                        type="number"
                        v-model.number="inputManualValue"
                        :suffix="selectedTableItem.order_unit"
                        min="1"
                        :disabled="forceZero"
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                    <v-container fluid>
                        <v-checkbox v-model="forceZero" label="発注しない" />
                    </v-container>
                    </v-col>
                </v-row>

                <v-spacer />

                <v-row>
                    <v-col>
                    <v-btn color="primary" :disabled="isManual && inputManualValue<=0" @click="confirm()">完了</v-btn>
                    </v-col>
                </v-row>
            </v-app-bar>

        </v-card>
    </v-dialog>
</template>

<script>
import { serviceRates, calcOrderNum } from './table_profile.js'
import RowDetailsTable from './RowDetailsTable'
import ResultsBarChart from './ResultsBarChart'

export default {
    components: {
        RowDetailsTable,
        ResultsBarChart
    },
    data: () => ({
        dialog: false,
        selectedOptionValue: {},
        inputManualValue: 1,
        noEdit: true,
        forceZero: false,

        graphTab: null,
        chartData: {},
        chartOptions: {}
    }),
    props: ['selectedTableItem', 'currentOptionValue', 'isForceZero', 'manualValue', 'results', 'duct'],
    computed: {
        isManual() {
            return this.selectedOptionValue.manual;
        },
        srOptions() {
            let tableItem = this.selectedTableItem;
            let srOptions = [];
//            console.log(`[${tableItem.product_code}] srOptions: sr=${tableItem.sr}, fp=${tableItem.fp}, fp_shp=${tableItem.fp_shp}`);
            srOptions = [
                { 
//                    value: { sr: tableItem.sr, fp: tableItem.fp },
                    value: { sr: 0, fp: 0, src: "" },
                    qty: tableItem['order_num'],
                    text: `発注量=${tableItem['order_num']}${tableItem['order_unit']}（変更なし）`
                },
                //...[...serviceRates].reduce((acc, sr) => {
                ...[...serviceRates].reverse().reduce((acc, sr) => {
                    const fp = tableItem[`fp_shp`];
                    const op = tableItem[`order_point_fp${fp}_sr${sr}`];
                    const qty = calcOrderNum(tableItem, op);
                    //const qty = calcOrderNum(tableItem, tableItem[`order_point_fp${fp}`]);

                    if((acc.length==0 || qty!=acc[0].qty) && qty!=tableItem['order_num']) {
                        const text = `発注量=${qty}${tableItem.order_unit}（事後確率≤${sr}%）`;
                        const cur = { qty, text, value: { sr, fp, src: "" } }
                        //console.log(cur);
                        acc.unshift(cur);
                    }
                    return acc;
                }, []),
            ];

            let key = `ml-gbdt-cls_fp${tableItem.fp_shp}_pred`;
            if (key in tableItem) {
                let qty = calcOrderNum(tableItem, tableItem[key]);
                //if (!srOptions.find((el) => {console.log(el); return el.qty == qty;})   ) {
                if (qty > 0) {
                    srOptions.push(
                        {
                            value: {sr: -1, fp: tableItem.fp_shp, src: 'gbdt-cls'},
                            qty: qty,
                            text: `発注量=${calcOrderNum(tableItem, tableItem["ml-gbdt-cls_fp"+tableItem.fp_shp+"_pred"])}${tableItem.order_unit}（GBDT事後確率最大）`,
                        }
                    );
                }
            }
            key = `ml-gbdt-reg_fp${tableItem.fp_shp}_pred`;
            if (key in tableItem) {
                let qty = calcOrderNum(tableItem, tableItem[key]);
                if (qty > 0) {
                //if (!srOptions.find((el) => {el.qty == qty})   ) {
                    srOptions.push(
                        {
                            value: {sr: -1, fp: tableItem.fp_shp, src: 'gbdt-reg'},
                            qty: qty,
                            text: `発注量=${calcOrderNum(tableItem, tableItem["ml-gbdt-reg_fp"+tableItem.fp_shp+"_pred"])}${tableItem.order_unit}（GBDT回帰予測）`,
                        }
                    );
                }
            }

            if ('classical_fp1_sr65' in tableItem) {
                srOptions = [
                    ...srOptions, 
                    ...[...serviceRates].reverse().reduce((acc, sr) => {
                    //...[...serviceRates].reduce((acc, sr) => {
                        const fp = tableItem['fp_shp'];
                        const op = tableItem[`classical_fp${fp}_sr${sr}`];
                        const qty = calcOrderNum(tableItem, op);
                        if((acc.length==0 || qty!=acc[0].qty)) {
                        //if((acc.length==0 || qty!=acc[0].qty) && qty!=tableItem['order_num']) {
                        //if(srOptions.find((el) => {el.qty == qty})) {
                            const text = `発注量=${qty}${tableItem.order_unit}（古典的・安全率≤${sr}%）`;
                            //console.log(`classical_text${text}`);
                            acc.unshift({ qty, text, value: { sr, fp: tableItem.fp_shp, src: 'classical' } });
                        }
                        return acc;
                    }, []),
                ];
            }
            srOptions = [
                ...srOptions, 
                ...[12,8,4,2,1]
                    //.filter((fp) => (fp!=tableItem['fp_shp']))
                    .map((fp) => {
                        const sr = tableItem['sr'];
                        const op = tableItem[`order_point_fp${fp}_sr${sr}`];
                        const qty = calcOrderNum(tableItem, op);
                        //const qty = calcOrderNum(tableItem, tableItem[`order_point_fp${fp}`]);
                        const text = `発注量=${qty}${tableItem.order_unit}（予測期間≤${fp}週）`;
                        //console.log(`order_point_fp${fp}:${qty}`);
                        return { qty, text, value: { sr, fp, src:"" } };
                    })
                    .reduce((acc,cur) => {
                        //console.log(cur);
                        if((acc.length==0 || cur.qty!=acc[0].qty)) {
                            //if(!acc.map((o)=>o.qty).includes(cur.qty)
                            //    && !srOptions.map((o)=>o.qty).includes(cur.qty))
                            //    acc.push(cur);
                            acc.unshift(cur);
                        }
                            return acc;
                        }, [])
            ];
            
            srOptions.sort((a,b) => a.qty-b.qty);
            srOptions = [
                ...srOptions,
                { 
                    value: { sr: tableItem.sr, fp: tableItem.fp_shp, manual: true },
                    text: `手動入力`
                },
            ];
            // for (const op of srOptions) {
            //     console.log(JSON.stringify(op));
            // }
            return srOptions;
        },
        fps() {
            return this.duct.FORECAST_CONFIG ? this.duct.FORECAST_CONFIG.forecast_periods : [];
        }
    },
    methods: {
        show() { this.dialog = true; },
        hide() { this.dialog = false; },
        confirm() {
            this.$emit("confirm", {
                item: this.selectedTableItem,
                optionValue: this.selectedOptionValue,
                manualValue: this.selectedOptionValue.manual ? this.inputManualValue : null,
                forceZero: this.forceZero
            });
            this.hide();
        },
    },
    watch: {
        selectedTableItem(item) {
            this.forceZero = this.isForceZero;
            this.selectedOptionValue = this.currentOptionValue;
            this.inputManualValue = this.manualValue || item['order_num'];
            const labels = item.order_unit=='箱' && !(item.order_unit_orig && item.order_unit_orig=='ケース') ? this.duct.FORECAST_CONFIG.labels.b : this.duct.FORECAST_CONFIG.labels.c;
            this.chartData = this.duct.FORECAST_CONFIG.forecast_periods.map((fp) => ({
                    labels,
                    datasets: [
                        {
                            type: 'bar',
                            label: '累積事後確率',
                            data: this.results[`fp-${fp}`][item.hash_key].map((r) => (r*100).toFixed(1)),
                            backgroundColor: labels.map((l) => l!=item[`order_point_fp${fp}`] ? 'rgba(54, 162, 235, 0.6)' : 'rgba(255, 0, 0, 0.6)'),
                            borderColor: labels.map((l) => l!=item[`order_point_fp${fp}`] ? 'rgba(54, 162, 235, 1)' : 'rgba(255, 0, 0, 1)'),
                            borderWidth: 1
                        },
                    ],
                }));
            this.chartOptions = this.duct.FORECAST_CONFIG.forecast_periods.map((fp) => ({
                    scales: {
                        xAxes: [{
                            scaleLabel: {
                                display: true,
                                labelString: `予測クラス（=${item.order_unit_orig || item.order_unit}数）`,
                            },
                        }],
                        yAxes: [{
                            scaleLabel: {
                                display: true,
                                labelString: `累積事後確率（${fp}週後予測；単位=%）`,
                            },
                            ticks: {
                                max: 100,
                                min: 0
                            },
                            id: 'y-axis-0',
                            type: 'linear'
                        }]
                    },
                    legend: {
                        display: false,
                    },
                    annotation: {
                        annotations: [{
                            type: 'line',
                            mode: 'horizontal',
                            borderWidth: 2,
                            borderColor: 'red',
                            value: item.sr,
                            scaleID: 'y-axis-0',
                        }]
                    }
                }));
            this.graphTab = this.duct.FORECAST_CONFIG.forecast_periods.findIndex((fp) => (fp===item.fp));
        }
    }
}
</script>
