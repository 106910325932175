import dateFormat from 'dateformat'

export const forecastPeriods = [1,2,4,8,12];

export const serviceRates = [60, 65, 70, 75, 80, 90, 95, 99, 99.5, 99.99];
const visibleServiceRates = [75, 80, 90];

const orderPoints = {
    case: [0, 1, 2, 3, 4, 5, 6, 8, 10, 12, 16, 24, 50, 75, 76],
    box: [0, 1, 2, 3, 4, 6, 8, 10]
};

export const alertType = {
    MODEL_CHANGED: {
        color: "cyan",
        text: "予測カテゴリ変更",
        value: 'MODEL_CHANGED'
    },
    IRREGULAR_PEAK: {
        color: "lime",
        text: "突発的予測",
        value: 'IRREGULAR_PEAK'
    },
    EXPENSIVE_ORDER: {
        color: "orange",
        text: "高額発注",
        value: 'EXPENSIVE_ORDER'
    },
    CUSTOM_RULE: {
        color: "grey",
        text: "カスタムルール適用",
        value: 'CUSTOM_RULE'
    },
    FP_CHANGED: {
        color: 'pink',
        text: '週数変更',
        value: 'FP_CHANGED'
    },
    FP_SUGGESTED: {
        color: 'pink lighten-4',
        text: '週数変更推奨',
        value: 'FP_SUGGESTED'
    }
};

const orderUnitNames = [
    ["case", "ケース"],
    ["box", "箱"]
];

const getOrderUnitName = (targetIdx, queryIdx=null, queryStr=null) => {
    if(queryIdx && queryStr) return orderUnitNames.find((x) => x[queryIdx]==queryStr)[targetIdx];
    else return orderUnitNames.map((x) => x[targetIdx]);
};

export const itemAttributes = [
    { level: 0, filterType: 'multiple', value: "alerts", text: "アラート" },
    { level: 0, filterType: 'string', value: "product_code", text: "品番" },
    { level: 0, filterType: 'string', value: "attr_product_name", text: "品名" },
    { level: 0, filterType: 'string', value: "attr_product_size", text: "サイズ" },
    { level: 0, filterType: 'multiple', value: "attr_product_rank", text: "ランク" },
    { level: 0, filterType: 'number', value: "order_num", text: "発注数", description: '0<発注数<最小発注量 のとき、最小発注量に上書き' },
    { level: 0, filterType: 'multiple', value: "order_unit", text: "発注単位" },
    { level: 0, filterType: 'number', value: "order_num_qty", text: "発注数（バラ）" },
    { level: 0, filterType: 'number', value: "order_price", text: "発注金額" },
    { level: 0, filterType: 'date', value: "order_delivery_time", text: "発注納期", description: "FPを基準とする" },
    { level: 0, filterType: 'multiple', value: "sr", text: "安全率" },
    { level: 0, filterType: 'multiple', value: "fp", text: "週数FP [発注点・納期決定用]", description: 'もしマスタ設定FPよりも直近の週に欠品が予想される場合、その週数に上書き' },
    { level: 0, filterType: 'multiple', value: "fp_shp", text: "週数FP' [発注量計算用]", description: 'もしFPが上書きされた場合、FPで上書き' },
    { level: 0, filterType: 'number', value: "order_point", text: "発注点" },
    { level: 0, filterType: 'number', value: "custom0", text: "発注数(調整)1", description: "発注数（計算）>平均出荷量（週）×１２<br>かつ<br>在庫数<平均出荷（週）<br>の場合、発注量は平均出荷数（週）×２（可能な場合のみ箱換算）。" },
    { level: 0, filterType: 'number', value: "custom1", text: "発注数(調整)2", description: "発注数（計算）>平均出荷量（週）×１２<br>かつ<br>在庫数>=平均出荷（週）<br>の場合、発注量は0。" },
    //{ level: 0, filterType: 'number', value: "custom0", text: "発注数(調整)1", description: "発注数が予測週数以内の引当数を下回る場合、発注数は<br>MIN(<br>&nbsp;&nbsp;&nbsp;&nbsp;引当数不足分,<br>&nbsp;&nbsp;&nbsp;&nbsp;過去32週間平均需要 x2<br>)" },
    //{ level: 0, filterType: 'number', value: "custom1", text: "発注数(調整)2", description: "発注数が3カ月分を上回り、発注数（計算）が1より大きい場合、発注数は1" },
    //{ level: 0, filterType: 'number', value: "custom2", text: "発注数(調整)3", description: "在庫数が0.5ヵ月分を下回る場合、発注数は<br>MAX(<br>&nbsp;&nbsp;&nbsp;&nbsp;発注数（計算）,<br>&nbsp;&nbsp;&nbsp;&nbsp;過去32週間平均需要 x2<br>)" },
    //{ level: 0, filterType: 'number', value: "custom3", text: "発注数(調整)4", description: "発注数が0.5ヵ月分を下回る場合、発注数は<br>MAX(<br>&nbsp;&nbsp;&nbsp;&nbsp;発注数（計算）,<br>&nbsp;&nbsp;&nbsp;&nbsp;過去32週間平均需要 x2<br>)" },
    //{ level: 0, filterType: 'number', value: "custom4", text: "発注数(調整)5", description: "発注数が6か月分を上回る場合、発注数は0" },
    { level: 0, filterType: 'number', value: "order_num_calc", text: "発注数(計算)", description: "FP'週後の予想不足分（予想量-(在庫+注残)）" },
    { level: 0, filterType: 'multiple', value: "minimum_order_num", text: "最小発注量" },
    { level: 0, filterType: 'string', value: "attr_supplier_name", text: "仕入先" },
    { level: 0, filterType: 'multiple', value: "forecast_type", text: "予測モデル" },
    { level: 0, filterType: 'multiple', value: "forecast_type_prev", text: "予測モデル（前週）" },
    { level: 0, filterType: 'number', value: "price", text: "単価" }, //
    ...orderUnitNames.map((n)=>(
        { level: 0, filterType: 'number', value: `${n[0]}_qty`, text: `${n[1]}入数` }
    )),
    ...visibleServiceRates.map((sr)=>(
        { level: 0, value: `order_point_sr${sr}`, text: `安全率=${sr}%` }
    )),
    { level: 0, filterType: 'number', value: "stocks", text: "残在庫" },
    { level: 0, filterType: 'number', value: "remains", text: "発注残" },
    { level: 0, filterType: 'number', value: "order_amount", text: "引当数" },
    { level: 0, filterType: 'number', value: "safe_stock", text: "安全在庫" },
    { level: 0, filterType: 'number', value: "months_order", text: "発注数は何ヶ月分？" },
    { level: 0, filterType: 'number', value: "months_stock", text: "残在庫は何ヶ月分？" },
];

export const headerFilterTypes = Object.fromEntries(itemAttributes.map((attr) => ([attr.value, attr.filterType])));

const unitQuantity = (item) => (item[`${getOrderUnitName(0,1,item.order_unit)}_qty`]);

export const calcOrderNum = (item, order_point=item['order_point']) => {
    return Math.max(
        0,
        Math.ceil((order_point*item["unit_qty"]-item["stocks"]-item["remains"])/item["unit_qty"])
    )
};

export const customRules = [
    {
        rule: (item) => (
                item.unit_qty==0 || item.order_num_calc < Math.ceil((item.order_amount-item.stocks-item.remains)/item.unit_qty)
                ?  Math.ceil((item.order_amount-item.stocks-item.remains)/item.unit_qty) :false
            ),
    },
    {
        rule: (item) => ( item.months_order>5.0 && item.order_num_calc==1 ? 0 : false ),
        
    },
    {
        rule: (item) => (
            item.months_stock<0.5
            ? Math.max( item.order_num_calc, Math.ceil(item.ave_last32*2/unitQuantity(item)) )
            : false
        ),
        
    },
    {
        rule: (item) => (
            item.months_order<0.5
            ? Math.max( item.order_num_calc, Math.ceil(item.ave_last32*2/unitQuantity(item)) )
            : false
        ),
        
    },
    {
        rule: (item) => ( item.months_order>6.0 ? 0 : false ),
    },
]

const _randomPickFrom = (obj) => (Array.isArray(obj) ? obj[Math.floor(Math.random()*obj.length)] : obj[Object.keys(obj)[Math.floor(Math.random()*obj.length)]]);
const _randomIntFromRange = (min, max, step=1) => (parseInt((Math.floor(Math.random() * (max - min + 1)) + min)/step)*step);
const _randomFloatFromRange = (min, max) => (Math.random() * (max - min + 1) + min);
const _indexPlaceholder = (name, i) => (`${itemAttributes.find(el => el.value==name).text}サンプル${("0000"+i).slice(-4)}`);

// サーバーで計算するものはここで計算する。つまり、testTableItemsがサーバーからそのまま渡ってくる。
// クライアントで計算するものはここの外で事後的に計算する。
// テスト段階で存在しないキーが入っていても無視される（詳細にのみ表示される）のでok。
export const testTableItems = [...Array(500)].map((e,i) => {
    const bases = {
        //alerts: [_randomPickFrom(alertType)],
        fp: _randomPickFrom(forecastPeriods),
        sr: _randomPickFrom(serviceRates),
        case_qty: _randomIntFromRange(100,10000,100),
        order_unit: _randomPickFrom(getOrderUnitName(1)),
        product_code: _indexPlaceholder("product_code", i),
        attr_product_name: _indexPlaceholder("attr_product_name", i),
        attr_product_size: _indexPlaceholder("attr_product_size", i),
        attr_supplier_name: _indexPlaceholder("attr_supplier_name", i),
        attr_product_rank: _randomPickFrom(["A","B","C","D","E"]),
        price: parseFloat(_randomFloatFromRange(1,3).toFixed(2)),
        remains: _randomIntFromRange(0,10000),
        stocks: _randomIntFromRange(0,10000),
        order_amount:_randomIntFromRange(1,10000),
        df_last8: _randomFloatFromRange(0,1),
        df_last16: _randomFloatFromRange(0,1),
        df_last32: _randomFloatFromRange(0,1),
        df_last64: _randomFloatFromRange(0,1),
        std_last8: _randomIntFromRange(2000,10000),
        std_last16: _randomIntFromRange(2000,10000),
        std_last32: _randomIntFromRange(2000,10000),
        std_last64: _randomIntFromRange(2000,10000),
        forecast_type: _randomPickFrom(["b0", "b1", "c0", "c1"]),
        forecast_type_prev: _randomPickFrom(["b0", "b1", "c0", "c1"]),
        checked: false,
        do_not_order: false
    };

    let deps = {}
    forecastPeriods.forEach((fp,i) => {
        serviceRates.forEach((sr,j) => {
            const ops = orderPoints[getOrderUnitName(0,1,bases.order_unit)];
            deps[`order_point_fp${fp}_sr${sr}`] =  // これは実際は送らない。_depsとかにする。テスト目的
                Math.max(
                    i==0 ? 0 : deps[`order_point_fp${forecastPeriods[i-1]}_sr${sr}`],
                    j==0 ? 0 : deps[`order_point_fp${fp}_sr${serviceRates[j-1]}`],
                    ops[_randomIntFromRange(0, ops.length-1)]
                )
            if(fp==bases.fp) {
                deps[`order_point_sr${sr}`] = deps[`order_point_fp${fp}_sr${sr}`];
            }
            if(sr==bases.sr){
                deps[`order_point_fp${fp}`] = deps[`order_point_fp${fp}_sr${sr}`];
            }
        });
    });
    
    //突発的予測を発生させるところ
    let rand_value = Math.random();  
    if (rand_value >= 0.99){
        deps[`order_point_fp${bases.fp}`] = 75;
        deps[`order_point_fp${bases.fp}_sr${bases.sr}`] = 75
    }


    deps.order_point = deps[`order_point_fp${bases.fp}_sr${bases.sr}`];
    deps.box_qty = Math.min(bases.case_qty, _randomIntFromRange(50, 2000, 20));
    deps.unit_qty = unitQuantity({ ...bases, ...deps });
    deps.safe_stock = deps[`order_point_fp1_sr${bases.sr}`];
    deps.ave_last8  = parseInt((_randomFloatFromRange(0,5)*(bases.order_unit=="ケース" ? bases["case_qty"] : deps["box_qty"])).toFixed(1));
    deps.ave_last16 = parseInt((_randomFloatFromRange(0,5)*(bases.order_unit=="ケース" ? bases["case_qty"] : deps["box_qty"])).toFixed(1));
    deps.ave_last32 = parseInt((_randomFloatFromRange(0,5)*(bases.order_unit=="ケース" ? bases["case_qty"] : deps["box_qty"])).toFixed(1));
    deps.ave_last64 = parseInt((_randomFloatFromRange(0,5)*(bases.order_unit=="ケース" ? bases["case_qty"] : deps["box_qty"])).toFixed(1));
    deps.order_num_calc = Math.ceil((deps.order_point*deps.unit_qty-bases.remains-bases.stocks)/deps.unit_qty);
    if (deps.order_num_calc < 0) deps.order_num_calc = 0;

    deps.months_order = deps.order_num_calc*deps.unit_qty/(deps.ave_last32*4);
    deps.months_order = parseFloat(deps.months_order.toFixed(2));
    deps.months_stock = bases.stocks/(deps.ave_last32*4);
    deps.months_stock = parseFloat(deps.months_stock.toFixed(2));
     
    customRules.forEach((customRules,i) => {
        deps[`custom${i}`] = customRules.rule({ ...bases, ...deps });
    });
    function order_num(item){
            for(const i in customRules) {if(item["custom"+i] !== false) return item["custom"+i];}
            return item.order_num_calc;
    }

    deps.order_num = order_num(deps);
    deps.order_price = Math.round(deps.order_num*deps.unit_qty*bases.price);
    deps.safe_stock  = deps[`order_point_fp1_sr${bases.sr}`];
    deps.order_num_qty = deps.order_num * deps.unit_qty;
    deps.alerts = [];
    if(bases.forecast_type != bases.forecast_type_prev) deps.alerts.push(alertType.MODEL_CHANGED);
    if (!(deps["order_point_fp1"]<=deps["order_point_fp2"] 
        && deps["order_point_fp2"]<=deps["order_point_fp4"] 
        && deps["order_point_fp4"]<=deps["order_point_fp8"] 
        && deps["order_point_fp8"]<=deps["order_point_fp12"])) deps.alerts.push(alertType.IRREGULAR_PEAK);
    if (deps.order_price >= 100000) deps.alerts.push(alertType.EXPENSIVE_ORDER);
    if (deps["custom0"]!=false || deps["custom1"]!=false || deps["custom2"]!=false || deps["custom3"]!=false || deps["custom4"]!=false) deps.alerts.push(alertType.CUSTOM_RULE) ;

    let dt = new Date(), daysFrom, daysTo;
    if(bases.fp==1) daysFrom = 1, daysTo = 1;
    else if(bases.fp==2) daysFrom = 2, daysTo = 4;
    else if(bases.fp==4) daysFrom = 10, daysTo = 15;
    else if(bases.fp==8) daysFrom = 20, daysTo = 30;
    else if(bases.fp==12) daysFrom = 40, daysTo = 50;
    dt.setDate(dt.getDate() + _randomIntFromRange(daysFrom,daysTo));
    deps.order_delivery_time = dateFormat(dt.getTime(), 'yyyy-mm-dd');

    
    return { ...bases, ...deps }
});
