<script>
//import { Bar, mixins } from 'vue-chartjs'
import { Bar } from 'vue-chartjs'
//const { reactiveProp } = mixins
import pluginAnnotation from 'chartjs-plugin-annotation'

export default {
    extends: Bar,
    //mixins: [reactiveProp],
    props: ['chartData', 'options'],
    watch: {
        chartData() {
            this.renderChart(this.chartData, this.options)
            //console.log(this.options);
        }
    },
    mounted() {
        this.addPlugin(pluginAnnotation);
        this.renderChart(this.chartData, this.options)
        //console.log(this.options);
    }
}
</script>
